// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// for work-miner
const firebaseConfig = {
  apiKey: "AIzaSyDvjdple88A2ftiyOIhnNSzJeJB1sB6VMs",
  authDomain: "arbitragetrade-io.firebaseapp.com",
  databaseURL: "https://arbitragetrade-io-default-rtdb.firebaseio.com",
  projectId: "arbitragetrade-io",
  storageBucket: "arbitragetrade-io.appspot.com",
  messagingSenderId: "932558221559",
  appId: "1:932558221559:web:7565daf6f8aed1a77ca3f2",
  measurementId: "G-L2WQET9QL8"
};

export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const storage = getStorage(app);
