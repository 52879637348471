import { ref, child, get, update, remove } from "firebase/database";
import { database } from "../firebase";
import { IsEmpty } from "../utils/isEmpty";

export const saveStore = async (store, data) => {
  const uniqueId = new Date().getTime().toString();
  const newRef = ref(database, `${store}/${uniqueId}`);

  await update(newRef, {
    id: uniqueId,
    ...data
  })
    .then(() => {
      console.log("stored successfully!");

      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const getStores = async (store) => {
  return new Promise((resolve, reject) => {
    const dbRef = ref(database);
    get(child(dbRef, `${store}/`)).then((snapshot) => {
      if (snapshot.exists()) {
        const res = snapshot.val();
        resolve(res);
      } else {
        resolve(null);
      }
    });
  });
};

export const getStore = async (store, id) => {
  return new Promise((resolve, reject) => {
    const res = ref(database, `${store}/${id}`);
    if (res !== null) {
      resolve(Object.entries(res));
    } else {
      resolve(null);
    }
  });
};

export const deleteStore = async (store, id) => {
  const dbRef = ref(database, `${store}/${id}`);
  remove(dbRef).then(() => {
    console.log("store deleted!");
  });
};

export const updateStore = async (store, id, data) => {
  const newRef = ref(database, `${store}/${id}`);
  console.log(newRef);
  await update(newRef, {
    id: id,
    ...data
  })
    .then(() => {
      console.log("updated successfully!");
      return true;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
};

export const checkAdmin = async (email, address) => {
  const userData = await getStores("AdminUser");
  return new Promise((resolve, reject) => {
    if (IsEmpty(userData)) resolve(false);
    const user = Object.entries(userData).pop();
    if (email === user[1].email && address === user[1].address) {
      resolve(true);
    } else {
      resolve(false);
    }
  });
};

// this is for approve address
