import axios from "axios";

export const subtractDates = (date1, date2) => {
  let ret = Math.floor(
    (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24)
  );
  return ret > 0 ? ret : 0;
};

export const percentPaid = (money) => {
  if (money >= 50 && money < 10000) return 0.02;
  else if (money < 50000) return 0.03;
  else if (money < 100000) return 0.04;
  else return 0.045;
};

export const getNewYorkDate = async () => {
  try {
    const res = await axios.get(
      "https://api.timezonedb.com/v2.1/get-time-zone?key=XDFSGSXEUEL0&format=json&by=zone&zone=America/New_York"
    );
    const newYorkDate = new Date(res.data.formatted);
    return newYorkDate;
  } catch (err) {
    console.log(err);
  }
};

export const convertToNewYorkSeven = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  let day = date.getDate();
  const hour = date.getHours();

  let convertedDate;
  if (hour < 19) {
    day--;
    convertedDate = new Date(year, month, day, 19, 0, 0);
  } else {
    convertedDate = new Date(year, month, day, 19, 0, 0);
  }
  return convertedDate;
};

export const reduceString = (str) => {
  let ret = "";
  if (str.length > 20) {
    ret = str.substring(0, 6) + "...." + str.slice(-4);
  } else {
    ret = str;
  }
  return ret;
};
