import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { atom_withdrawal, isWithdrawShow } from "../atom";

const Withdraw = () => {
  const [withdraw, setWithdraw] = useState(0);
  const setWithdrawModal = useSetRecoilState(isWithdrawShow);
  const setWithdrawal = useSetRecoilState(atom_withdrawal);

  const withdrawHandle = () => {
    setWithdrawal(withdraw);
    setWithdrawModal(false);
  };

  return (
    <div className="items-center">
      <div className="grid grid-rows-2 grid-cols-3 text-black text-lg font-normal font-sans gap-3">
        <div>
          <p className="text-right">Amount of Withdrawal:</p>
        </div>
        <div className="col-span-2">
          <input
            type="text"
            id="txt_withdraw"
            name="txt_withdraw"
            className="w-full rounded-sm border p-3"
            placeholder="Input User Id Here."
            value={withdraw}
            onChange={(e) => {
              setWithdraw(e.target.value);
            }}
          />
        </div>

        <div className="col-span-3 text-right mt-2">
          <button
            className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-6 py-3 text-white hover:bg-blue-500"
            onClick={withdrawHandle}
          >
            Withdraw
          </button>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
