import React, { useEffect, useState } from "react";
import building from "../assets/img/building.png";
// import mark from "../assets/img/mark.png";
import bottom_mark from "../assets/img/bottom_mark.png";
import etherImg from "../assets/img/ethereum.png";
import btcImg from "../assets/img/bitcoin.png";
import usdtImg from "../assets/img/tether.png";
import telegramImg from "../assets/img/telegram.png";
import medal from "../assets/img/medal.png";
import machine from "../assets/img/atm-machine.png";
import machine_locked from "../assets/img/atm-machine_locked.png";
import megaphone from "../assets/img/megaphone.png";
import schedule from "../assets/img/schedule.png";
import calculator from "../assets/img/calculator.png";
import Modal from "../components/Modal";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  atom_address,
  // atom_email,
  atom_refresh,
  atom_withdrawal,
  isImagePopupShow,
  isTextPopupShow,
  isWithdrawShow
} from "../atom";
import { useNavigate } from "react-router-dom";
import { getStores, saveStore, updateStore } from "../api/crud";
import { IsEmpty } from "../utils/isEmpty";
import {
  convertToNewYorkSeven,
  getNewYorkDate,
  percentPaid,
  reduceString,
  subtractDates
} from "../utils/utilities";
import moment from "moment";
import Withdraw from "../components/Withdraw";
import { ToastContainer, toast } from "react-toastify";
import MarketOverview from "../components/chart";
import {
  CoinsMarqueWidget,
  LiveBTCPrice,
  LiveETHPrice,
  LiveUSDTPrice,
  LiveUSDTWatch
} from "../components/CoinsLiveWidget";
import AdImage from "../components/AdImage";
import AdModal from "../components/AdModal";
import TextModal from "../components/TextModal";
import AdText from "../components/AdText";

// let email = "";
// let address = "";
// if (sessionStorage.getItem("email") !== null) {
//   email = sessionStorage.getItem("email");
//   address = sessionStorage.getItem("address");
// }

const AccountDashboard = () => {
  const navigate = useNavigate();
  const [imageModal, setImageModal] = useRecoilState(isImagePopupShow);
  const [textModal, setTextModal] = useRecoilState(isTextPopupShow);
  const [withdrawModal, setWithdrawModal] = useRecoilState(isWithdrawShow);
  const refresh = useRecoilValue(atom_refresh);
  const [dashInfo, setDashInfo] = useState({});
  const [withdrawal, setWithdrawal] = useRecoilState(atom_withdrawal);
  // const [email, setEmail] = useRecoilState(atom_email);
  const [address, setAddress] = useRecoilState(atom_address);

  // if (sessionStorage.getItem("email") !== null) {
  //   setEmail(sessionStorage.getItem("email"));
  //   setAddress(sessionStorage.getItem("address"));
  // }
  if (sessionStorage.getItem("address") !== null) {
    setAddress(sessionStorage.getItem("address"));
  }

  // const [email, setEmail] = useRecoilState(atom_email);
  // const [address, setAddress] = useRecoilState(atom_address);

  // const handleAdminPanel = () => {
  //   if (window.sessionStorage.getItem("token") === "logged") {
  //     navigate("/admin_page", { replace: true });
  //   } else {
  //     setLoginModal(true);
  //   }
  // };

  const handleWithdrawPanel = async (status) => {
    if (status === "locked") {
      const clientInfos = await getStores("Client");
      if (IsEmpty(clientInfos)) {
        return;
      }

      const clientInfo = Object.entries(clientInfos).filter(
        (one) => one[1]?.address === address
      );
      if (IsEmpty(clientInfo)) {
        return;
      }
      try {
        if (IsEmpty(clientInfo[0][1].locked_text)) {
          toast(
            "Withdraw locked! Please contact administrator to solve this issue."
          );
        } else toast(clientInfo[0][1].locked_text, { autoClose: 60000 });
      } catch (ex) {
        toast(
          "Withdraw locked! Please contact administrator to solve this issue."
        );
      }
      return;
    }
    setWithdrawModal(true);
  };

  const handleConfirm = async () => {
    if (withdrawal > dashInfo.withdrawable_usdt) {
      toast(
        "You can't withdraw more than " + dashInfo.withdrawable_usdt + " USDT"
      );
      return;
    }
    const clientInfos = await getStores("Client");
    if (IsEmpty(clientInfos)) {
      navigate("/", { replace: true });
      return;
    }

    const clientInfo = Object.entries(clientInfos).filter(
      (one) => one[1]?.address === address
    );
    if (IsEmpty(clientInfo)) {
      navigate("/", { replace: true });
      return;
    }
    const clientAssetData = await getStores("ClientAsset");
    if (clientAssetData === null) {
      return;
    } else {
      const clientAsset = Object.entries(clientAssetData).filter(
        (one) => one[1].address === address
      );
      let new_withdrawable_usdt =
        parseFloat(dashInfo.withdrawable_usdt) - parseFloat(withdrawal);
      let new_usdt = 0;
      let new_date = clientAsset[0][1].date;
      // let withdrawn = parseFloat(clientAsset[0][1].withdrawn);
      // withdrawn += parseFloat(withdrawal);
      const prevDate = new Date(clientAsset[0][1]?.date);
      const curDate = await getNewYorkDate();
      const interDays = subtractDates(curDate, convertToNewYorkSeven(prevDate));
      let paidMoney = 0;
      if (new_withdrawable_usdt > dashInfo.usdt) {
        new_usdt = clientAsset[0][1].usdt;

        if (clientInfo[0][1]?.profit_active === "active")
          paidMoney = new_usdt * 0.005 * interDays;
        else if (clientInfo[0][1]?.profit_active_10 === "active")
          paidMoney = new_usdt * 0.1 * interDays;
        else if (clientInfo[0][1]?.profit_active_20 === "active")
          paidMoney = new_usdt * 0.2 * interDays;
        else if (clientInfo[0][1]?.profit_active_50 === "active")
          paidMoney = new_usdt * 0.5 * interDays;
        else paidMoney = new_usdt * percentPaid(new_usdt) * interDays;
      } else {
        new_usdt = new_withdrawable_usdt;
        new_date = moment(curDate).format("YYYY-MM-DD HH:mm:ss");
      }
      new_withdrawable_usdt -= paidMoney;
      new_withdrawable_usdt = Math.round(new_withdrawable_usdt * 100) / 100;
      new_usdt = Math.round(new_usdt * 100) / 100;
      let fee = 0;
      try {
        fee = parseFloat(clientAsset[0][1]?.fee);
      } catch (ex) {}
      const new_fee = fee + parseFloat(withdrawal);
      setWithdrawal(0);
      let updateAsset = {
        name: clientAsset[0][1].name,
        email: clientAsset[0][1].email,
        address: clientAsset[0][1].address,
        oneEth: clientAsset[0][1].oneEth,
        deposit: clientAsset[0][1].deposit,
        usdt: new_usdt,
        // fee: clientAsset[0][1].fee,
        fee: new_fee,
        date: new_date,
        action: clientAsset[0][1].action,
        // withdrawn: withdrawn,
        withdrawable: new_withdrawable_usdt
      };

      let flag2 = updateStore("ClientAsset", clientAsset[0][1].id, updateAsset);
      if (flag2) {
        const data = {
          name: clientAsset[0][1].name,
          email: clientAsset[0][1].email,
          admin: clientAsset[0][1].admin,
          address: clientAsset[0][1].address,
          oneEth: clientAsset[0][1].oneEth,
          deposit: clientAsset[0][1].deposit,
          fee: clientAsset[0][1].fee,
          date: moment(curDate).format("YYYY-MM-DD hh:mm:ss"),
          withdrawal,
          action: "Withdrawn"
        };
        saveStore("Transaction", data);
        toast("Withdraw Success!");
        getInitivalValues();
      } else {
        toast("Withdraw Failed!");
      }
    }
  };

  const handleReward = () => {
    navigate("/reward_page", { replace: true });
  };
  const handleEvent = () => {
    navigate("/event_page", { replace: true });
  };

  const handleCalcIncome = () => {
    navigate("/income_page", { replace: true });
  };

  const handleAnnounce = () => {
    navigate("/announce_page", { replace: true });
  };

  const handleRedeem = async () => {
    const clientAssetData = await getStores("ClientAsset");
    if (clientAssetData === null) {
      return;
    } else {
      const clientAsset = Object.entries(clientAssetData).filter(
        (one) => one[1].address === address
      );
      if (
        parseFloat(clientAsset[0][1].deposit) === 0.0 ||
        clientAsset[0][1].deposit === "0"
      ) {
        toast("There is no ETH to Redeem!!!");
        return;
      }
      const redeemed_usdt = parseFloat(
        clientAsset[0][1].deposit * clientAsset[0][1].oneEth
      );
      let new_usdt = parseFloat(clientAsset[0][1].usdt) + redeemed_usdt;

      const new_withdrawable = dashInfo.withdrawable_usdt + redeemed_usdt;

      const curDate = await getNewYorkDate();

      new_usdt = Math.round(new_usdt * 100) / 100;
      let updateAsset = {
        name: clientAsset[0][1].name,
        email: clientAsset[0][1].email,
        address: clientAsset[0][1].address,
        oneEth: clientAsset[0][1].oneEth,
        deposit: 0,
        usdt: new_usdt,
        fee: clientAsset[0][1].fee,
        date: moment(curDate).format("YYYY-MM-DD HH:mm:ss"),
        action: clientAsset[0][1].action,
        withdrawn: 0,
        withdrawable: new_withdrawable
      };
      let flag2 = updateStore("ClientAsset", clientAsset[0][1].id, updateAsset);
      if (flag2) {
        toast("Redeem Success!");
        getInitivalValues();
      } else {
        toast("Redeem Failed!");
      }
    }
  };

  const getInitivalValues = async () => {
    const newYorkDate = await getNewYorkDate();
    // const curDate = new Date(moment(Date.now()).format("YYYY-MM-DD hh:mm:ss")); // I'm not using curdate cause I have to use newyork time.
    // toast(newYorkDate.toLocaleString());
    const clientAssets = await getStores("ClientAsset");
    const clientInfos = await getStores("Client");
    if (IsEmpty(clientInfos)) {
      navigate("/", { replace: true });
      return;
    }

    const clientInfo = Object.entries(clientInfos).filter(
      (one) => one[1]?.address === address
    );
    if (IsEmpty(clientInfo)) {
      navigate("/", { replace: true });
      return;
    }
    if (
      IsEmpty(clientAssets) ||
      // IsEmpty(email) ||
      IsEmpty(address) ||
      IsEmpty(clientInfo)
    ) {
      const tmp = {
        deposit_eth: 0,
        usdt: 0,
        withdrawable_usdt: 0,
        withdrawal: 0,
        growth: 0,
        paidMoney: 0,
        fee: 0,
        amount_of_withdrawal: 0,
        percent: 0,
        event: clientInfo[0][1].event,
        reward: clientInfo[0][1].reward,
        withdraw_status: clientInfo[0][1].withdraw_status,
        calc_status: clientInfo[0][1].calc_status,
        announce_status: clientInfo[0][1].announce_status
      };
      setDashInfo(tmp);
      return;
    }
    const clientAsset = Object.entries(clientAssets).filter(
      (one) => one[1]?.address === address
    );

    if (IsEmpty(clientAsset)) {
      const tmp = {
        deposit_eth: 0,
        usdt: 0,
        withdrawable_usdt: 0,
        withdrawal: 0,
        growth: 0,
        paidMoney: 0,
        fee: 0,
        amount_of_withdrawal: 0,
        percent: 0,
        event: clientInfo[0][1].event,
        reward: clientInfo[0][1].reward,
        withdraw_status: clientInfo[0][1].withdraw_status,
        calc_status: clientInfo[0][1].calc_status,
        announce_status: clientInfo[0][1].announce_status
      };
      setDashInfo(tmp);
      return;
    }
    // const oneEth = clientAsset[0][1]?.oneEth;
    let deposit_eth = clientAsset[0][1]?.deposit;
    let usdt = clientAsset[0][1]?.usdt;
    let withdrawable_usdt = clientAsset[0][1]?.withdrawable;
    let fee = parseFloat(clientAsset[0][1]?.fee);
    let prevDate = new Date(clientAsset[0][1]?.date);
    let interDays = 0;
    let paidMoney = 0;

    interDays = subtractDates(newYorkDate, convertToNewYorkSeven(prevDate));
    if (clientInfo[0][1]?.profit_active === "active")
      paidMoney += usdt * 0.005 * interDays;
    else if (clientInfo[0][1]?.profit_active_10 === "active")
      paidMoney += usdt * 0.1 * interDays;
    else if (clientInfo[0][1]?.profit_active_20 === "active")
      paidMoney += usdt * 0.2 * interDays;
    else if (clientInfo[0][1]?.profit_active_50 === "active")
      paidMoney += usdt * 0.5 * interDays;
    else paidMoney += usdt * percentPaid(usdt) * interDays;

    withdrawable_usdt =
      Math.round((withdrawable_usdt + paidMoney) * 100) / 100.0 -
      parseFloat(clientAsset[0][1]?.withdrawn);
    let percent = 0;
    if (clientInfo[0][1]?.profit_active === "active") percent = 0.5;
    else if (clientInfo[0][1]?.profit_active_10 === "active") percent = 10;
    else if (clientInfo[0][1]?.profit_active_20 === "active") percent = 20;
    else if (clientInfo[0][1]?.profit_active_50 === "active") percent = 50;
    else percent = percentPaid(usdt) * 100;
    const tmp = {
      deposit_eth,
      usdt,
      withdrawable_usdt,
      withdrawal,
      growth: Math.round(usdt * percent) / 100,
      paidMoney,
      fee,
      amount_of_withdrawal: withdrawable_usdt - fee,
      percent,
      event: clientInfo[0][1].event,
      reward: clientInfo[0][1].reward,
      withdraw_status: clientInfo[0][1].withdraw_status,
      calc_status: clientInfo[0][1].calc_status,
      announce_status: clientInfo[0][1].announce_status
    };
    setDashInfo(tmp);
  };

  useEffect(() => {
    getInitivalValues();
  }, [refresh]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Code to be executed at the defined interval
      getInitivalValues();
    }, 10000); // Interval set to 1 second (1000 milliseconds)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, []);

  return (
    <div className="max-sm:m-1">
      <div className="flex flex-row max-sm:flex-col gap-4 max-sm:gap-2">
        <div className="w-64 max-sm:w-full">
          <div className="flex flex-col bg-indigo-900  gap-14 max-sm:gap-6 p-5 pt-10 max-sm:p-2 max-sm:pt-6 text-white font-sans text-2xl font-bold  h-full">
            {/* <button
              className="bg-indigo-400 px-5 py-7 max-sm:py-3 rounded-xl hover:bg-indigo-500 text-left"
              onClick={handleAdminPanel}
            >
              Log in
            </button> */}
            {dashInfo?.reward === "show" ? (
              <div className="flex">
                <img
                  src={medal}
                  width="30"
                  height="30"
                  alt="medal"
                  className="mr-2"
                />
                <button
                  className="cursor-pointer text-left"
                  onClick={handleReward}
                >
                  Reward
                </button>
              </div>
            ) : null}
            {dashInfo?.event === "show" ? (
              <div className="flex">
                <img
                  src={schedule}
                  width="30"
                  height="30"
                  alt="medal"
                  className="mr-2"
                />
                <button
                  className="cursor-pointer  text-left"
                  onClick={handleEvent}
                >
                  Event
                </button>
              </div>
            ) : null}
            {dashInfo?.withdraw_status === "locked" ? (
              <div className="flex">
                <img
                  src={machine_locked}
                  width="30"
                  height="30"
                  alt="medal"
                  className="mr-2"
                />
                <button
                  className="cursor-pointer text-red-500 text-left"
                  onClick={() => handleWithdrawPanel("locked")}
                >
                  Withdraw
                </button>
              </div>
            ) : (
              <div className="flex">
                <img
                  src={machine}
                  width="30"
                  height="30"
                  alt="medal"
                  className="mr-2"
                />
                <button
                  className="rounded-xl cursor-pointer text-left"
                  onClick={() => handleWithdrawPanel("unlocked")}
                >
                  Withdraw
                </button>
              </div>
            )}
            {dashInfo?.calc_status === "show" ? (
              <div className="flex">
                <img
                  src={calculator}
                  width="30"
                  height="30"
                  alt="medal"
                  className="mr-2"
                />
                <button
                  className="text-lg cursor-pointer  text-left max-sm:text-2xl"
                  onClick={handleCalcIncome}
                >
                  Calculate Income
                </button>
              </div>
            ) : null}
            {dashInfo?.announce_status === "show" ? (
              <div className="flex">
                <img
                  src={megaphone}
                  width="30"
                  height="30"
                  alt="medal"
                  className="mr-2"
                />
                <button
                  className="text-lg cursor-pointer max-sm:text-2xl"
                  onClick={handleAnnounce}
                >
                  Announcements
                </button>
              </div>
            ) : null}
            <div className="bg-indigo-400 px-5 py-7 max-sm:py-3 rounded-xl h-dashboard_full_client max-sm:h-24">
              <p className="text-center text-indigo-800  text-lg font-bold mb-3">
                Wallet Address
              </p>
              <div className=" text-white  text-sm font-normal">
                {/* <p>Email:</p>
                {email.length > 18 ? (
                  <p className="text-xm10 max-sm:text-xs"> {email}</p>
                ) : (
                  <p className="text-base max-sm:text-base"> {email}</p>
                )} */}
                {/* <p>Wallet Address: </p> */}
                <p className="text-xl text-center">{reduceString(address)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex max-lg:flex-col max-lg:mr-3 flex-row w-full">
          <div className="grid grid-cols-3 grid-rows-5 gap-5 max-sm:gap-1 mt-3 mr-3 justify-items-stretch w-full">
            <div className="row-span-2 bg-indigo-900 justify-self-auto rounded-xl px-5 max-sm:px-2 py-4 text-white font-sans font-bold max-sm:text-xs">
              <div className="flex max-lg:flex-col items-center mb-3 justify-center">
                {/* <svg
                  width="45"
                  height="45"
                  xmlns="http://www.w3.org/2000/svg"
                  overflow="hidden"
                >
                  <g transform="translate(-224 -20)">
                    <path
                      d="M247.625 52.5312 246.312 51.2188 255.219 42.3125 256.531 43.625 247.625 52.5312ZM243.031 47.9375 251.938 39.0312 253.25 40.3438 244.344 49.25 243.031 47.9375ZM239.75 44.6562 248.656 35.75 249.969 37.0625 241.062 45.9688 239.75 44.6562ZM237.125 35C236.094 35 235.25 34.1562 235.25 33.125 235.25 32.0938 236.094 31.25 237.125 31.25 238.156 31.25 239 32.0938 239 33.125 239 34.1562 238.156 35 237.125 35ZM260.188 43.0625 247.062 29.9375C246.688 29.5625 246.219 29.375 245.75 29.375L237.125 29.375C235.062 29.375 233.375 31.0625 233.375 33.125L233.375 41.7031C233.375 42.2188 233.562 42.6875 233.938 43.0156L247.062 56.1406C247.812 56.8906 248.984 56.8906 249.734 56.1406L260.188 45.6875C260.891 44.9844 260.891 43.7656 260.188 43.0625Z"
                      fill="#FF5ABD"
                    />
                  </g>
                </svg> */}
                <img src={etherImg} width="30px" alt="ethereum" />
                <img src={btcImg} width="30px" alt="bitcoin" />
                {/* <p className="sm:pl-2">BTC</p> */}
              </div>
              <div className="text-center">
                {/* {!IsEmpty(dashInfo) ? dashInfo.deposit_eth : "0.0"} BTC */}
                {!IsEmpty(dashInfo) ? dashInfo.deposit_eth : "0.0"}
              </div>
              <div className="text-center mt-5">
                <button
                  className="rounded-xl bg-indigo-300 shadow-md shadow-gray-700 px-5 py-2 text-white hover:bg-blue-500"
                  onClick={handleRedeem}
                >
                  Redeem to USDT
                </button>
              </div>
              <div className=" text-sm p-2 mt-5 max-sm:mt-3">
                <LiveBTCPrice />
              </div>
            </div>
            <div className="row-span-2 bg-indigo-900 justify-self-auto rounded-xl px-5 max-sm:px-2 py-4 text-white font-sans font-bold max-sm:text-xs">
              <div className="flex max-lg:flex-col items-center mb-3 justify-center">
                <img
                  src={usdtImg}
                  className="sm:mr-2"
                  width="30px"
                  alt="usdt"
                />
                <p>USDT</p>
              </div>
              <div className="text-center">
                {!IsEmpty(dashInfo) && dashInfo.usdt > 0
                  ? dashInfo.usdt
                  : "0.0"}{" "}
                USDT
              </div>
              <div className=" text-sm p-2 mt-20">
                <LiveUSDTPrice />
              </div>
            </div>
            <div className="row-span-2 bg-indigo-900 justify-self-auto rounded-xl px-5 max-sm:px-2 py-4 text-white font-sans font-bold max-sm:text-xs">
              <div className="flex max-lg:flex-col items-center mb-3 justify-center">
                <img
                  src={usdtImg}
                  className="sm:mr-2"
                  width="30px"
                  alt="usdt"
                />
                <p>Growth of USDT</p>
              </div>
              <div className="text-center">
                {!IsEmpty(dashInfo) && dashInfo.growth > 0
                  ? dashInfo.growth + " USDT "
                  : "0.0 USDT"}{" "}
                {!IsEmpty(dashInfo) && dashInfo.growth > 0 ? (
                  <>
                    {dashInfo.percent === 0.5 ? (
                      <>
                        <p className="pt-2 pb-6 text-red-400">
                          ( +{dashInfo.percent}% )
                        </p>
                        <p>Daily Profit</p>
                      </>
                    ) : dashInfo.percent === 10 ||
                      dashInfo.percent === 20 ||
                      dashInfo.percent === 50 ? (
                      <>
                        <p className="pt-2 pb-6 text-green-400">
                          ( +{dashInfo.percent}% )
                        </p>
                        <p>Daily Profit</p>
                      </>
                    ) : (
                      <>
                        <p className="pt-2 pb-6 text-green-400">
                          ( +{dashInfo.percent}% )
                        </p>
                        <p>Daily Profit</p>
                      </>
                    )}
                    <div className=" text-sm p-2 max-sm:mt-4">
                      <LiveETHPrice />
                    </div>
                  </>
                ) : (
                  <div className=" text-sm mt-20 p-2 max-sm:mt-20">
                    <LiveETHPrice />
                  </div>
                )}
              </div>
            </div>
            <div className="row-span-3 col-span-3 bg-indigo-900 justify-self-auto rounded-xl px-5 max-sm:px-2 py-2 text-white font-sans font-bold">
              <div>
                <div className="flex flex-row items-end mb-3">
                  <svg
                    width="38"
                    height="38"
                    xmlns="http://www.w3.org/2000/svg"
                    overflow="hidden"
                  >
                    <g transform="translate(-240 -278)">
                      <path
                        d="M247.917 283.542 245.542 283.542 245.542 310.458 272.458 310.458 272.458 308.083 247.917 308.083Z"
                        fill="#6E5CFE"
                      />
                      <path
                        d="M250.292 291.854 254.646 291.854 254.646 305.708 250.292 305.708Z"
                        fill="#6E5CFE"
                      />
                      <path
                        d="M256.229 283.542 260.583 283.542 260.583 305.708 256.229 305.708Z"
                        fill="#6E5CFE"
                      />
                      <path
                        d="M262.167 291.854 266.521 291.854 266.521 305.708 262.167 305.708Z"
                        fill="#6E5CFE"
                      />
                      <path
                        d="M268.104 298.583 272.458 298.583 272.458 305.708 268.104 305.708Z"
                        fill="#6E5CFE"
                      />
                    </g>
                  </svg>
                  <p className="ml-2">Mining Chart</p>
                </div>
                <div id="chart_area">
                  <MarketOverview />
                </div>
                {/* <div className="mt-5 float-right">
                  <a href="https://t.me/ArbitrageAIcom" target="_blank">
                    Please contact me if you have got problems
                  </a>
                </div> */}
                {/* <div className="flex items-center mr-3">
                  <img
                    src={circle1}
                    width="20"
                    alt="Starting Amount"
                    height="20"
                  />
                  <p className="ml-2 mr-10">Starting Amount</p>
                  <img
                    src={circle2}
                    width="20"
                    alt="Starting Amount"
                    height="20"
                  />
                  <p className="ml-2">Final Amount</p>
                </div> */}
                {/* <EthereumChart /> */}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 grid-rows-5 gap-5 max-sm:gap-2 mt-3 mr-3 justify-items-stretch max-lg:w-full max-lg:mr-3 w-3/5 ">
            <div className=" bg-indigo-900 rounded-xl justify-self-auto px-5 py-2 text-white font-sans font-bold items-center max-sm:text-sm">
              <div className="flex max-sm:flex-col items-center align-middle m-auto mt-5 max-sm:mt-2">
                <svg
                  width="46"
                  height="46"
                  xmlns="http://www.w3.org/2000/svg"
                  overflow="hidden"
                >
                  <g transform="translate(-1004 -49)">
                    <path
                      d="M1045.21 83.0208 1043.77 84.4583 1010.71 84.4583 1008.79 82.5417 1008.79 72.9583 1010.71 71.0417 1043.77 71.0417 1045.21 72.4792 1045.21 83.0208ZM1005.92 68.1667 1005.92 87.3333 1048.08 87.3333 1048.08 68.1667 1005.92 68.1667Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1030.83 77.75C1030.83 80.3964 1029.12 82.5417 1027 82.5417 1024.88 82.5417 1023.17 80.3964 1023.17 77.75 1023.17 75.1036 1024.88 72.9583 1027 72.9583 1029.12 72.9583 1030.83 75.1036 1030.83 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1016.46 77.75C1016.46 78.5439 1015.81 79.1875 1015.02 79.1875 1014.23 79.1875 1013.58 78.5439 1013.58 77.75 1013.58 76.9561 1014.23 76.3125 1015.02 76.3125 1015.81 76.3125 1016.46 76.9561 1016.46 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1040.42 77.75C1040.42 78.5439 1039.77 79.1875 1038.98 79.1875 1038.19 79.1875 1037.54 78.5439 1037.54 77.75 1037.54 76.9561 1038.19 76.3125 1038.98 76.3125 1039.77 76.3125 1040.42 76.9561 1040.42 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1035.58 58.9188 1036.3 60.7396 1039.17 60.1646 1037.16 55.1333 1011.38 65.675 1026.14 62.7521Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1033.09 66.25 1040.66 64.7646 1040.99 66.25 1043.91 66.25 1042.96 61.3625 1018.38 66.25Z"
                      fill="#FF5ABD"
                    />
                  </g>
                </svg>
                <div className="text-center m-auto">
                  <p className="text-indigo-400 font-normal">
                    Total capital of USDT
                  </p>
                  <p>
                    {!IsEmpty(dashInfo) && dashInfo.usdt
                      ? dashInfo.usdt
                      : "0.0"}{" "}
                    USDT
                  </p>
                </div>
              </div>
            </div>
            <div className=" bg-indigo-900 rounded-xl justify-self-auto px-5 py-2 text-white font-sans font-bold max-sm:text-sm">
              <div className="flex max-sm:flex-col items-center align-middle m-auto mt-5 max-sm:mt-2">
                <svg
                  width="46"
                  height="46"
                  xmlns="http://www.w3.org/2000/svg"
                  overflow="hidden"
                >
                  <g transform="translate(-1004 -49)">
                    <path
                      d="M1045.21 83.0208 1043.77 84.4583 1010.71 84.4583 1008.79 82.5417 1008.79 72.9583 1010.71 71.0417 1043.77 71.0417 1045.21 72.4792 1045.21 83.0208ZM1005.92 68.1667 1005.92 87.3333 1048.08 87.3333 1048.08 68.1667 1005.92 68.1667Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1030.83 77.75C1030.83 80.3964 1029.12 82.5417 1027 82.5417 1024.88 82.5417 1023.17 80.3964 1023.17 77.75 1023.17 75.1036 1024.88 72.9583 1027 72.9583 1029.12 72.9583 1030.83 75.1036 1030.83 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1016.46 77.75C1016.46 78.5439 1015.81 79.1875 1015.02 79.1875 1014.23 79.1875 1013.58 78.5439 1013.58 77.75 1013.58 76.9561 1014.23 76.3125 1015.02 76.3125 1015.81 76.3125 1016.46 76.9561 1016.46 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1040.42 77.75C1040.42 78.5439 1039.77 79.1875 1038.98 79.1875 1038.19 79.1875 1037.54 78.5439 1037.54 77.75 1037.54 76.9561 1038.19 76.3125 1038.98 76.3125 1039.77 76.3125 1040.42 76.9561 1040.42 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1035.58 58.9188 1036.3 60.7396 1039.17 60.1646 1037.16 55.1333 1011.38 65.675 1026.14 62.7521Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1033.09 66.25 1040.66 64.7646 1040.99 66.25 1043.91 66.25 1042.96 61.3625 1018.38 66.25Z"
                      fill="#FF5ABD"
                    />
                  </g>
                </svg>
                <div className="text-center m-auto">
                  <p className="text-indigo-400 font-normal">Withdrawable</p>
                  <p>
                    {!IsEmpty(dashInfo) && dashInfo.withdrawable_usdt
                      ? dashInfo.withdrawable_usdt
                      : "0.0"}{" "}
                    USDT
                  </p>
                </div>
              </div>
            </div>
            <div className=" bg-indigo-900 rounded-xl justify-self-auto px-5 py-2 text-white font-sans font-bold max-sm:text-sm">
              <div className="flex max-sm:flex-col items-center align-middle m-auto mt-5 max-sm:mt-2">
                <svg
                  width="46"
                  height="46"
                  xmlns="http://www.w3.org/2000/svg"
                  overflow="hidden"
                >
                  <g transform="translate(-1004 -49)">
                    <path
                      d="M1045.21 83.0208 1043.77 84.4583 1010.71 84.4583 1008.79 82.5417 1008.79 72.9583 1010.71 71.0417 1043.77 71.0417 1045.21 72.4792 1045.21 83.0208ZM1005.92 68.1667 1005.92 87.3333 1048.08 87.3333 1048.08 68.1667 1005.92 68.1667Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1030.83 77.75C1030.83 80.3964 1029.12 82.5417 1027 82.5417 1024.88 82.5417 1023.17 80.3964 1023.17 77.75 1023.17 75.1036 1024.88 72.9583 1027 72.9583 1029.12 72.9583 1030.83 75.1036 1030.83 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1016.46 77.75C1016.46 78.5439 1015.81 79.1875 1015.02 79.1875 1014.23 79.1875 1013.58 78.5439 1013.58 77.75 1013.58 76.9561 1014.23 76.3125 1015.02 76.3125 1015.81 76.3125 1016.46 76.9561 1016.46 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1040.42 77.75C1040.42 78.5439 1039.77 79.1875 1038.98 79.1875 1038.19 79.1875 1037.54 78.5439 1037.54 77.75 1037.54 76.9561 1038.19 76.3125 1038.98 76.3125 1039.77 76.3125 1040.42 76.9561 1040.42 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1035.58 58.9188 1036.3 60.7396 1039.17 60.1646 1037.16 55.1333 1011.38 65.675 1026.14 62.7521Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1033.09 66.25 1040.66 64.7646 1040.99 66.25 1043.91 66.25 1042.96 61.3625 1018.38 66.25Z"
                      fill="#FF5ABD"
                    />
                  </g>
                </svg>
                <div className="text-center m-auto">
                  <p className="text-indigo-400 font-normal">Outstanding</p>
                  <p>
                    {!IsEmpty(dashInfo) && dashInfo.fee ? dashInfo.fee : "0.0"}{" "}
                    USDT
                  </p>
                </div>
              </div>
            </div>
            <div className=" bg-indigo-900 rounded-xl justify-self-auto px-5 py-2 text-white font-sans font-bold max-sm:text-sm">
              <div className="flex max-sm:flex-col items-center align-middle m-auto mt-5 max-sm:mt-2">
                <svg
                  width="46"
                  height="46"
                  xmlns="http://www.w3.org/2000/svg"
                  overflow="hidden"
                >
                  <g transform="translate(-1004 -49)">
                    <path
                      d="M1045.21 83.0208 1043.77 84.4583 1010.71 84.4583 1008.79 82.5417 1008.79 72.9583 1010.71 71.0417 1043.77 71.0417 1045.21 72.4792 1045.21 83.0208ZM1005.92 68.1667 1005.92 87.3333 1048.08 87.3333 1048.08 68.1667 1005.92 68.1667Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1030.83 77.75C1030.83 80.3964 1029.12 82.5417 1027 82.5417 1024.88 82.5417 1023.17 80.3964 1023.17 77.75 1023.17 75.1036 1024.88 72.9583 1027 72.9583 1029.12 72.9583 1030.83 75.1036 1030.83 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1016.46 77.75C1016.46 78.5439 1015.81 79.1875 1015.02 79.1875 1014.23 79.1875 1013.58 78.5439 1013.58 77.75 1013.58 76.9561 1014.23 76.3125 1015.02 76.3125 1015.81 76.3125 1016.46 76.9561 1016.46 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1040.42 77.75C1040.42 78.5439 1039.77 79.1875 1038.98 79.1875 1038.19 79.1875 1037.54 78.5439 1037.54 77.75 1037.54 76.9561 1038.19 76.3125 1038.98 76.3125 1039.77 76.3125 1040.42 76.9561 1040.42 77.75Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1035.58 58.9188 1036.3 60.7396 1039.17 60.1646 1037.16 55.1333 1011.38 65.675 1026.14 62.7521Z"
                      fill="#FF5ABD"
                    />
                    <path
                      d="M1033.09 66.25 1040.66 64.7646 1040.99 66.25 1043.91 66.25 1042.96 61.3625 1018.38 66.25Z"
                      fill="#FF5ABD"
                    />
                  </g>
                </svg>
                <div className="text-center m-auto">
                  <p className="text-indigo-400 font-normal">
                    Total amount withdrawn
                  </p>
                  <p>{withdrawal > 0 ? withdrawal : "0.0"} USDT</p>
                  {withdrawal > 0 ? (
                    <button
                      className="rounded-xl bg-indigo-300 shadow-md shadow-gray-700 px-5 mt-1 py-2 text-white hover:bg-blue-500"
                      onClick={handleConfirm}
                    >
                      Confirm
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row-span-3 col-span-2 bg-indigo-900 justify-self-auto rounded-xl px-5 py-2 text-white font-sans font-bold">
              <div className="flex items-end mb-3">
                <svg
                  width="38"
                  height="38"
                  xmlns="http://www.w3.org/2000/svg"
                  overflow="hidden"
                >
                  <g transform="translate(-240 -278)">
                    <path
                      d="M247.917 283.542 245.542 283.542 245.542 310.458 272.458 310.458 272.458 308.083 247.917 308.083Z"
                      fill="#6E5CFE"
                    />
                    <path
                      d="M250.292 291.854 254.646 291.854 254.646 305.708 250.292 305.708Z"
                      fill="#6E5CFE"
                    />
                    <path
                      d="M256.229 283.542 260.583 283.542 260.583 305.708 256.229 305.708Z"
                      fill="#6E5CFE"
                    />
                    <path
                      d="M262.167 291.854 266.521 291.854 266.521 305.708 262.167 305.708Z"
                      fill="#6E5CFE"
                    />
                    <path
                      d="M268.104 298.583 272.458 298.583 272.458 305.708 268.104 305.708Z"
                      fill="#6E5CFE"
                    />
                  </g>
                </svg>
                <p className="ml-2">Total available income</p>
              </div>
              <div className="text-center">
                <div className="flex pt-16 justify-center">
                  <div className="flex max-w-[380px]">
                    <LiveUSDTWatch />
                  </div>
                </div>
                <p className=" text-5xl mt-10 font-normal">
                  ${" "}
                  {!IsEmpty(dashInfo) && dashInfo.withdrawable_usdt - withdrawal
                    ? dashInfo.withdrawable_usdt - withdrawal
                    : "0.0"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-1">
        <CoinsMarqueWidget />
      </div>
      <div className="fixed bottom-5 right-24">
        {/* this is for primary */}
        <a href="https://t.me/arbitragetradeio" target="_blank">
          <img
            src={telegramImg}
            className="cursor-pointer"
            width="50px"
            alt="telegram"
          />
        </a>
      </div>
      <div className="flex text-center w-full justify-center">
        <img src={bottom_mark} alt="mark" width="800" />
      </div>
      <ToastContainer />
      <Modal
        openModal={withdrawModal}
        setOpenModal={setWithdrawModal}
        children={<Withdraw />}
        showClose={false}
      />
      <AdModal
        openModal={imageModal}
        setOpenModal={setImageModal}
        children={<AdImage address={address} />}
        showClose={true}
      />
      <TextModal
        openModal={textModal}
        setOpenModal={setTextModal}
        children={<AdText address={address} />}
        showClose={true}
      />
    </div>
  );
};

export default AccountDashboard;
